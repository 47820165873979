import { ReactElement } from 'react';
import styles from './AdminHeader.module.scss';

export type AdminHeaderProps = {
  heading?: string;
  children?: ReactElement | string;
};

export const AdminHeader = ({ heading, children }: AdminHeaderProps) => {
  return (
    <div className={styles.container}>
      <h2>{heading}</h2>
      {children && <div className={styles.children}>{children}</div>}
    </div>
  );
};
