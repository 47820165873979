import { MenuItem } from '../MenuItem';

import cx from 'classnames';
import styles from './MenuModal.module.scss';
import { ReversMenuItem } from '../MenuItem/MenuItem';

export type MenuModalItem = {
  alt: string;
  className?: string;
  iconSrc: string;
  onClick: () => void;
  title: string;
  divider?: boolean;
  hideOnDesktop?: boolean;
};

export type MenuModalProps = {
  active?: boolean;
  customClass?: string;
  items: Array<MenuModalItem | null>;
  setActive?: (active: boolean) => void;
  isReverse?: boolean;
};

export const MenuModal = ({ active, customClass, items = [], setActive, isReverse }: MenuModalProps) => {
  return (
    <>
      <div className={cx(styles.container, customClass, { [styles.active]: active })}>
        {items.map((itemProps, index) =>
          itemProps ? (
            isReverse ? (
              <ReversMenuItem
                className={cx({ [styles.divider]: itemProps.divider, [styles.hideOnDesktop]: itemProps.hideOnDesktop })}
                key={index}
                {...itemProps}
              />
            ) : (
            <MenuItem
              className={cx({ [styles.divider]: itemProps.divider, [styles.hideOnDesktop]: itemProps.hideOnDesktop })}
              key={index}
              {...itemProps}
            />
            )
          ) : null,
        )}
      </div>
      <div className={cx(styles.cover, { [styles.active]: active })} onClick={() => setActive && setActive(false)} />
    </>
  );
};
