import React, { ReactElement, useEffect } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useAppSelector } from 'store/hooks';
import { selectCurrentUser } from 'store';
import { ReduxDrivenModal } from 'components/Modal';
import { AccountMenu } from 'components/AccountMenu';
import { ReduxDrivenSnackbar } from 'components/Snackbar';
import { AdminSidebar } from './AdminSidebar';
import { AdminHeader } from './AdminHeader';
import { isServer } from 'utils/services';

import styles from './AdminPanel.module.scss';

export type AdminPanelWrapperProps = {
  children: ReactElement;
  title?: string;
  heading?: string;
  sidebarMarker?: string;
};

export const AdminPanelWrapper = ({ title, children, heading, sidebarMarker }: AdminPanelWrapperProps) => {
  const router = useRouter();
  const currentUser = useAppSelector(selectCurrentUser);
  const isAuth = !!Object.keys(currentUser || {}).length;

  useEffect(() => {
    if (!isAuth) {
      router.push('/sign-in').then(() => {});
      return;
    }
  }, [currentUser]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!isServer()) {
    if (!document.body.classList.contains('hiddenChatBot')) {
      document.body.classList.add('hiddenChatBot');
    }
  }

  return (
    <>
      <ReduxDrivenSnackbar />
      <ReduxDrivenModal />
      <Head>
        <title>{title}</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width, height=device-height" />
        <meta httpEquiv="content-type" content="text/html; charset=UTF-8" />
        {/* eslint-disable-next-line @next/next/no-css-tags */}
        <link rel="stylesheet" href="//cdn.quilljs.com/1.2.6/quill.snow.css" />
      </Head>
      {isAuth && (
        <div className={styles.container}>
          <AdminSidebar activeLink={sidebarMarker || heading || ''} currentUser={currentUser} />
          <div className={styles.mainWrapper}>
            <AdminHeader heading={heading}>
              <AccountMenu user={currentUser} />
            </AdminHeader>
            <div className={styles.mainContent}>{children}</div>
          </div>
        </div>
      )}
    </>
  );
};
