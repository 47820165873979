import { useState } from 'react';
import Image from 'next/legacy/image';
import Link from 'next/link';
import { ADMIN_LINKS } from 'consts';
import { CurrentUser } from 'types';
import { NextLink } from 'components/NextLink';

import cx from 'classnames';
import styles from './AdminSidebar.module.scss';

export type AdminSidebarProps = {
  activeLink: string;
  currentUser?: CurrentUser | null;
};

export const AdminSidebar = ({ activeLink, currentUser = {} }: AdminSidebarProps) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleCollapsed = () => {
    setIsCollapsed((collapsed) => !collapsed);
  };

  const availableLinks = ADMIN_LINKS.filter((link) => link.checkPermissions(currentUser));

  return (
    <div className={cx(styles.container, { [styles.collapsedBar]: isCollapsed })}>
      <div className={styles.logoLink}>
        <Link href="/" legacyBehavior={true}>
          <a href="#">
            {isCollapsed ? (
              <Image src="/icons/LogoIconic.svg" width={32} height="14" alt="SENDY" />
            ) : (
              <Image src="/icons/LogoPrimaryHorizontal.svg" width="91" height="14" alt="SENDY" />
            )}
          </a>
        </Link>
      </div>
      <div className={styles.links}>
        {availableLinks.map((link) => {
          const isActive = activeLink === link.name;
          return (
            <NextLink
              key={link.name}
              href={link.link}
              className={cx(styles.linkContainer, { [styles.activeLinkContainer]: isActive })}
              useDirectLink={true}
            >
              <>
                <Image
                  src={`${link.icon}${isActive ? 'Color.svg' : 'Light.svg'}`}
                  width={24}
                  height={24}
                  alt={link.name}
                />
                <p className={cx({ [styles.collapsed]: isCollapsed })}>{link.name}</p>
              </>
            </NextLink>
          );
        })}
      </div>
      <div className={styles.collapseButton} onClick={toggleCollapsed}>
        <Image
          src={`/icons/arrows/ArrowLine${isCollapsed ? 'Right' : 'Left'}Light.svg`}
          width={24}
          height={24}
          alt="Expand"
        />
        <p className={cx({ [styles.collapsed]: isCollapsed })}>Collapse</p>
      </div>
    </div>
  );
};
